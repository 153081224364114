<template>
  <div>
    <v-card class="ma-1 pa-1">
      <v-card-title class="pa-1">
        <span>{{ $t("directory.index") }}</span>
        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-autocomplete
            clearable
            v-model="filter"
            :label="$t('directory.type')"
            :items="directory_types"
            @change="getList"
            item-value="id"
            item-text="text"
            hide-details="auto"
            dense
            outlined
          ></v-autocomplete>
        </v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="mr-2"
          style="width: 100px !important"
          :placeholder="$t('search')"
          @keyup.native.enter="getList"
          outlined
          dense
          single-line
          hide-details
        ></v-text-field>

        <v-btn
          v-if="$store.getters.checkRole('directory')"
          color="#6ac82d"
          x-small
          dark
          fab
          @click="newItem"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        dense
        fixed-header
        :loading-text="$t('loadingText')"
        :no-data-text="$t('noDataText')"
        :height="screenHeight"
        :loading="loading"
        :headers="headers"
        item-key="id"
        :items="items"
        class="ma-1"
        style="border: 1px solid #aaa"
        :server-items-length="server_items_length"
        :options.sync="dataTableOptions"
        :disable-pagination="true"
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100, -1],
          itemsPerPageAllText: $t('itemsPerPageAllText'),
          itemsPerPageText: $t('itemsPerPageText'),
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
        }"
        @update:page="updatePage"
        @update:items-per-page="updatePerPage"
      >
        <template v-slot:item.id="{ item }">{{
          items
            .map(function (x) {
              return x.id;
            })
            .indexOf(item.id) + 1
        }}</template>

        <template v-slot:item.directory_type_id="{ item }">
          <span style="white-space: normal; max-width: 100px">{{
            item.directory_type
              ? item.directory_type["name_" + $i18n.locale]
              : ""
          }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="$store.getters.checkRole('directory')"
            color="blue"
            small
            text
            @click="editItem(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="$store.getters.checkRole('directory')"
            color="red"
            small
            text
            @click="deleteItem(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialog"
      @keydown.esc="dialog = false"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <span class="headline">{{ addType ? $t('directory.type') : $t('directory.index') }}</span>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined x-small fab class @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form @keyup.native.enter="save" ref="dialogForm" v-if="!addType">
            <v-row>
              <v-col cols="12">
                <label for>{{ $t("directoryType") }}</label>
                <v-autocomplete
                  clearable
                  v-model="form.directory_type_id"
                  :items="directory_types"
                  :rules="[(v) => !!v || $t('input.required')]"
                  hide-details="auto"
                  item-value="id"
                  dense
                  outlined
                >
                <template v-slot:append-outer>
                  <v-btn @click="addType = !addType" outlined color="success" class="mt-n2"><v-icon>mdi-plus</v-icon></v-btn>
                </template>

                </v-slot>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <label for>{{ $t("code") }}</label>
                <v-text-field
                  v-model="form.code"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label for>{{ $t("name_uz_latin") }}</label>
                <v-text-field
                  v-model="form.name_uz_latin"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label for>{{ $t("name_uz_cyril") }}</label>
                <v-text-field
                  v-model="form.name_uz_cyril"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label for>{{ $t("name_ru") }}</label>
                <v-text-field
                  v-model="form.name_ru"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-row v-else>
            <v-col cols="12">
                <label for>{{ $t("directory.name_uz_latin") }}</label>
                <v-text-field
                  v-model="typeForm.name_uz_latin"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                >
                  <template v-slot:append-outer>
                    <v-btn @click="addType = !addType" outlined color="error" class="mt-n2"><v-icon>mdi-close</v-icon></v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <label for>{{ $t("directory.name_uz_cyril") }}</label>
                <v-text-field
                  v-model="typeForm.name_uz_cyril"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <label for>{{ $t("directory.name_ru") }}</label>
                <v-text-field
                  v-model="typeForm.name_ru"
                  :rules="[(v) => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                >
                </v-text-field>
              </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save">{{ $t("save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading" width="300" hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("loadingText") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const axios = require("axios").default;
import Swal from "sweetalert2";
export default {
  data() {
    return {
      addType: false,
      typeForm: {
        name_uz_latin: "",
        name_uz_cyril: "",
        name_ru: "",
      },
      loading: false,
      search: "",
      page: 1,
      from: 0,
      server_items_length: -1,
      dataTableOptions: { page: 1, itemsPerPage: 50 },
      dialog: false,
      editMode: null,
      items: [],
      companies: [],
      directory_types: [],
      form: {},
      filter: 1,
      dialogHeaderText: "",
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight - 170;
    },
    headers() {
      return [
        { text: "#", value: "id", align: "center", width: 40 },
        { text: this.$t("code"), value: "code", width: 30, sortable: true },
        { text: this.$t("staff.name_uz_latin"), value: "name_uz_latin" },
        { text: this.$t("staff.name_uz_cyril"), value: "name_uz_cyril" },
        { text: this.$t("staff.name_ru"), value: "name_ru" },
        // { text: this.$t("directory_type"), value: "directory_type_id" },
        {
          text: this.$t("actions"),
          value: "actions",
          width: 120,
        },
      ].filter(
        (v) =>
          v.value != "actions" ||
          this.$store.getters.checkRole("directory") ||
          this.$store.getters.checkRole("directory")
      );
    },
  },
  methods: {
    updatePage($event) {
      this.getList();
    },
    updatePerPage($event) {
      this.getList();
    },

    getList() {
      this.loading = true;
      axios
        .post(this.$store.state.backend_url + "api/directories", {
          pagination: this.dataTableOptions,
          search: this.search,
          filter: this.filter,
        })
        .then((response) => {
          this.items = response.data.directories.data;
          this.from = response.data.directories.from;
          this.server_items_length = response.data.directories.total;
          this.directory_types = response.data.directory_types.map((v) => {
            return { text: v["name_" + this.$i18n.locale], id: v.id };
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    newItem() {
      this.dialogHeaderText = this.$t("directory.add");
      this.form = {
        id: Date.now(),
        name_uz_latin: "",
        name_uz_cyril: "",
        name_ru: "",
        code: "",
        directory_type_id: this.filter,
      };
      this.dialog = true;
      if (this.$refs.dialogForm) this.$refs.dialogForm.reset();
    },

    editItem(item) {
      this.dialogHeaderText = this.$t("directory.edit");
      this.formIndex = this.items.indexOf(item);
      this.form = Object.assign({}, item);
      this.dialog = true;
      if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation();
    },
    save() {
      if (this.addType) this.saveType();
      else {
        if (this.$refs.dialogForm.validate())
          axios
            .post(
              this.$store.state.backend_url + "api/directories/update",
              this.form
            )
            .then((res) => {
              this.getList();
              this.dialog = false;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: this.$t("create_update_operation"),
              });
            })
            .catch((err) => {
              console.log(err);
            });
      }
    },
    saveType() {
      axios
        .post(
          this.$store.state.backend_url + "api/directories/add-type",
          this.typeForm
        )
        .then((res) => {
          this.directory_types.push({
            text: res.data["name_" + this.$i18n.locale],
            id: res.data.id,
          });
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: this.$t("create_update_operation"),
          });
          this.addType = false;
          this.typeForm = {
            name_uz_latin: '',
            name_uz_cyril:'',
            name_ru: ''
          };
        })
        .catch((err) => {
          console.log(err);
          this.addType = false;
          this.typeForm = {
            name_uz_latin: '',
            name_uz_cyril:'',
            name_ru: ''
          };
        });
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      Swal.fire({
        title: this.$t("swal_title"),
        text: this.$t("swal_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("swal_delete"),
      }).then((result) => {
        if (result.value) {
          axios
            .delete(
              this.$store.state.backend_url +
                "api/directories/delete/" +
                item.id
            )
            .then((res) => {
              this.getList(this.page, this.itemsPerPage);
              this.dialog = false;
              Swal.fire("Deleted!", this.$t("swal_deleted"), "success");
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: this.$t("swal_error_title"),
                text: this.$t("swal_error_text"),
                //footer: "<a href>Why do I have this issue?</a>"
              });
              console.log(err);
            });
        }
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
